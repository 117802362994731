export const theme = {
  fonts: {
    family: {
      heading: 'Josefin Sans',
      normal: 'Roboto',
    },
  },
  colors: {
    primary: '#eb6569',
    secondary: '#2f3360',
    neutral: '#9f9f9f',
    neutralLight: '#d3d3d3',
  },
}
