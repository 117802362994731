import React from 'react'
import styled from 'styled-components'
import { theme } from 'styles'

import Logo from 'assets/Scran_Logo_Reversed_Colour_RGB.inline.svg'

interface FullScreenOverlayProps {
  children: React.ReactNode
}

const OuterWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.secondary};
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
`

export const FullScreenOverlay: React.FC<FullScreenOverlayProps> = ({ children }) => {
  return (
    <OuterWrapper>
      <div>
        <Logo />
        {children}
      </div>
    </OuterWrapper>
  )
}
